import type { FigmaPluginReceiveMessageType } from "@landwich/shared/types";
import { FIGMA_PLUGIN_ID } from "../constants";

export const sendMessageToFigma = ({
  message,
}: {
  message: FigmaPluginReceiveMessageType;
}) => {
  window.parent.postMessage(
    { pluginMessage: message, pluginId: FIGMA_PLUGIN_ID },
    "https://www.figma.com"
  );
};
