import { type RefObject, type MouseEvent, type ReactNode } from "react";
import {
  AlertModal as DSAlertModal,
  Button,
  LoadingSpinner,
} from "@landwich/ds";

interface Props {
  isOpen: boolean;
  isButtonLoading?: boolean;
  closeModal: () => void;
  handleChangeButtonClick: (e: MouseEvent<HTMLButtonElement>) => void;
  title?: string | ReactNode;
  description: string;
  changeText: string;
  cancelText?: string;
  contentNode?: React.ReactNode;
  level?: number;
  modalRef?: RefObject<HTMLDivElement>;
}

const BasicAlertModal = ({
  title,
  description,
  changeText,
  cancelText = "Cancel",
  handleChangeButtonClick,
  isOpen,
  isButtonLoading,
  closeModal,
  contentNode,
  level = 0,
  modalRef,
}: Props) => {
  return (
    <DSAlertModal
      closeModal={closeModal}
      isOpen={isOpen}
      level={level}
      modalRef={modalRef}
      title="Warning"
    >
      <DSAlertModal.Body className="flex flex-col gap-2">
        <DSAlertModal.Content description={description} title={title} />
        {contentNode && contentNode}
      </DSAlertModal.Body>
      <DSAlertModal.Footer>
        {isButtonLoading ? (
          <div className="w-[230px] px-8 py-4 bg-charcoal_200 rounded-full flex justify-center">
            <LoadingSpinner color="charcoal_400" size="sm" />
          </div>
        ) : (
          <Button
            className="w-[230px]"
            color="red"
            onClick={handleChangeButtonClick}
            paddingX={8}
            paddingY={4}
            textVariant="text-lg-medium"
          >
            {changeText}
          </Button>
        )}

        <Button
          className="w-[230px]"
          color="white"
          onClick={closeModal}
          paddingX={8}
          paddingY={4}
          textVariant="text-lg-medium"
        >
          {cancelText}
        </Button>
      </DSAlertModal.Footer>
    </DSAlertModal>
  );
};

export default BasicAlertModal;
