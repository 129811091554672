import { AlertModal as DSAlertModal, Button } from "@landwich/ds";
import type { PropsWithChildren } from "react";
import useModal from "../../hooks/useModal";

interface Props {
  handleChangeButtonClick: () => void;
  title: string;
  description: string;
  changeText: string;
  cancleText?: string;
  contentNode?: React.ReactNode;
}

const TriggerAlertModal = ({
  title,
  description,
  changeText,
  cancleText = "Cancel",
  handleChangeButtonClick,
  children,
  contentNode,
}: PropsWithChildren<Props>) => {
  const { isOpen, closeModal, triggerChildren } = useModal(children);

  return (
    <>
      {triggerChildren}
      <DSAlertModal closeModal={closeModal} isOpen={isOpen} title="Warning">
        <DSAlertModal.Body className="flex flex-col gap-2">
          <DSAlertModal.Content description={description} title={title} />
          {contentNode && contentNode}
        </DSAlertModal.Body>
        <DSAlertModal.Footer>
          <Button
            className="w-[230px]"
            color="red"
            onClick={() => {
              handleChangeButtonClick();
              closeModal();
            }}
            paddingX={8}
            paddingY={4}
            textVariant="text-lg-medium"
          >
            {changeText}
          </Button>
          <Button
            className="w-[230px]"
            color="white"
            onClick={closeModal}
            paddingX={8}
            paddingY={4}
            textVariant="text-lg-medium"
          >
            {cancleText}
          </Button>
        </DSAlertModal.Footer>
      </DSAlertModal>
    </>
  );
};

export default TriggerAlertModal;
