import { useRef } from "react";
import type { PropsWithChildren, RefObject } from "react";
import { motion, AnimatePresence } from "framer-motion";
import type { IconNames } from "@landwich/ds";
import { Icon, Portal } from "@landwich/ds";
import { useBodyScrollLock, useOnClickOutside } from "@landwich/shared/hooks";

interface Props {
  isOpen: boolean;
  icon: IconNames;
  title: string;
  closeModal: () => void;
  restRefs?: RefObject<HTMLElement>[];
}

const SettingModal = ({
  isOpen,
  icon,
  title,
  closeModal,
  children,
  restRefs,
}: PropsWithChildren<Props>) => {
  useBodyScrollLock(isOpen);

  const ref = useRef(null);

  useOnClickOutside({
    targetRef: ref,
    handler: closeModal,
    restRefs,
  });

  return (
    <Portal id="modal">
      <AnimatePresence>
        {isOpen && (
          <>
            <section className="fixed w-screen h-screen flex justify-center items-center inset-0 z-modal">
              <motion.div
                animate={{ opacity: 1 }}
                className="w-[860px] p-16 bg-white rounded-lg flex flex-col gap-8"
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                ref={ref}
                style={{
                  height: "min(calc(100vh - 184px), 840px)",
                  minHeight: "480px",
                }}
                transition={{
                  duration: 0.3,
                  ease: "easeIn",
                }}
              >
                <div className="flex items-center justify-between">
                  <div className="flex gap-4 items-center">
                    <Icon icon={icon} size={38} />
                    <h2 className="text-3xl-semibold">{title}</h2>
                  </div>
                  <Icon
                    className="cursor-pointer"
                    icon="ph:x"
                    onClick={closeModal}
                    size={19}
                  />
                </div>
                <section className="overflow-y-scroll scrollbar-hide">
                  {children}
                </section>
              </motion.div>
            </section>
            <div
              className="fixed w-screen h-screen z-modal-dimmer inset-0"
              style={{
                background: "rgba(0, 0, 0, 0.5)",
              }}
            />
          </>
        )}
      </AnimatePresence>
    </Portal>
  );
};

export default SettingModal;
