import Image from "next/image";
import React, { useState } from "react";

type Props = React.ComponentProps<typeof Image>;

const Favicon = (props: Props) => {
  const [isError, setIsError] = useState(false);

  const imageSrc =
    isError || props.src === "" ? "/images/default-favicon.png" : props.src;

  return (
    <Image
      {...props}
      className="rounded-lg"
      onError={() => {
        setIsError(true);
      }}
      src={imageSrc}
    />
  );
};

export default Favicon;
