import { AnimatePresence } from "framer-motion";
import { useAtomValue } from "jotai";
import { Portal, Toast } from "@landwich/ds";
import { toastAtom } from "@/shared/@common/store/index.atom";

const ToastProvider = (): JSX.Element | null => {
  const toasts = useAtomValue(toastAtom);

  if (toasts.length >= 1) {
    return (
      <Portal id="toast">
        <div className="fixed left-1/2 top-8 z-toast flex translate-x-[-50%] flex-col justify-center gap-4 w-fit items-center">
          <AnimatePresence>
            {toasts &&
              toasts.map(({ id, message, type, handleOnError }) => (
                <Toast
                  handleOnError={handleOnError}
                  key={id}
                  message={message}
                  type={type}
                />
              ))}
          </AnimatePresence>
        </div>
      </Portal>
    );
  }
  return null;
};

export default ToastProvider;
