import { BasicModal, Button } from "@landwich/ds";
import { type PropsWithChildren } from "react";
import useModal from "@/shared/@common/hooks/useModal";
import { type ProductInfo } from "../../constants/productInfoZod";
import CompetitorSettingLayout from "./CompetitorSettingLayout";

interface Props {
  isPending?: boolean;
  onSubmit: (data: ProductInfo, closeModal: () => void) => void;
}

const AddCompetitorModal = ({
  children,
  onSubmit,
  isPending = false,
}: PropsWithChildren<Props>): JSX.Element => {
  const { isOpen, closeModal, triggerChildren } = useModal(children);

  const handleCompetitorAddSubmit = (data: ProductInfo) => {
    onSubmit(data, closeModal);
  };

  return (
    <>
      {triggerChildren}
      <BasicModal isOpen={isOpen}>
        <BasicModal.Header
          icon="ph:magic-wand"
          title="Who is your competitor?"
        />
        <BasicModal.Body className="scrollbar-hide">
          <CompetitorSettingLayout onSubmit={handleCompetitorAddSubmit}>
            <BasicModal.Footer className="flex gap-4 justify-center">
              <Button color="white" onClick={closeModal} paddingX={8}>
                Cancel
              </Button>
              <Button
                className="!w-[200px]"
                color="black"
                disabled={isPending}
                type="submit"
              >
                Add
              </Button>
            </BasicModal.Footer>
          </CompetitorSettingLayout>
        </BasicModal.Body>
      </BasicModal>
    </>
  );
};

export default AddCompetitorModal;
