import { useState } from "react";
import cn from "classnames";
import { AnimateDropdownWrapper, Icon } from "@landwich/ds";
import { useRouter } from "next/navigation";
import { openLinkByNewWindow } from "@landwich/shared/utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { ROUTER_PATH } from "@/shared/@common/constants/router";
import { sendMessageToFigma } from "@/shared/figma/utils/sendMessageToFigma";

const FigmaMenuDropdown = () => {
  const router = useRouter();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogOutClick = () => {
    sendMessageToFigma({
      message: {
        type: "logout",
        data: null,
      },
    });
    router.replace(ROUTER_PATH.figmaRoot);
  };

  const handleDashBoardClick = () => {
    const currentUrl = new URL(window.location.href);
    currentUrl.pathname = ROUTER_PATH.comparison;

    openLinkByNewWindow(currentUrl.toString());
  };

  return (
    <DropdownMenu.Root
      defaultOpen={false}
      onOpenChange={(open) => setIsDropdownOpen(open)}
      open={isDropdownOpen}
    >
      <DropdownMenu.Trigger
        className={cn(
          "w-10 h-10 p-2 hover:bg-charcoal_50 outline-none rounded",
          {
            "bg-charcoal_50": isDropdownOpen,
          }
        )}
      >
        <Icon color="charcoal_500" icon="ph:list" size={24} />
      </DropdownMenu.Trigger>

      <AnimateDropdownWrapper
        align="end"
        className="shadow-md bg-white rounded border border-charcoal_200 overflow-hidden"
        zIndexClass="z-10"
      >
        <DropdownMenu.DropdownMenuItem
          className="px-6 py-4 text-md-medium text-charcoal_500 hover:bg-charcoal_50 outline-none"
          onClick={handleDashBoardClick}
        >
          Go to Dashboard
        </DropdownMenu.DropdownMenuItem>
        <DropdownMenu.DropdownMenuItem
          className="px-6 py-4 text-md-medium text-charcoal_500 hover:bg-charcoal_50 outline-none"
          onClick={handleLogOutClick}
        >
          Logout
        </DropdownMenu.DropdownMenuItem>
      </AnimateDropdownWrapper>
    </DropdownMenu.Root>
  );
};

export default FigmaMenuDropdown;
