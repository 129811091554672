import type { PropsWithChildren } from "react";
import React from "react";
import type { ProductInfo } from "@/shared/@common/constants/productInfoZod";
import ProductInfoForm from "../../ProductInfoForm";
import ProductInfoLayout from "../../ProductInfoLayout";

interface Props {
  onSubmit: (data: ProductInfo) => void;
}

const CompetitorSettingLayout = ({
  onSubmit,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <ProductInfoLayout onSubmit={onSubmit}>
      {({ watch, register, setValue, formState: { errors } }) => (
        <section className="flex flex-col justify-between h-full">
          <div className="flex flex-col gap-8 overflow-scroll pb-6 scrollbar-hide">
            <ProductInfoForm
              errors={errors}
              register={register}
              setValue={setValue}
              watch={watch}
            />
          </div>
          {children}
        </section>
      )}
    </ProductInfoLayout>
  );
};

export default CompetitorSettingLayout;
