import Image from "next/image";
import { useState } from "react";
import cn from "classnames";
import { useRouter } from "next/navigation";
import { AnimateDropdownWrapper, Icon, LoadingSpinner } from "@landwich/ds";
import { useLoadingState } from "@landwich/shared/hooks";
import { isUndefined } from "@landwich/shared/utils";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import type { GetUserDetailResponseDataDto } from "@our-pairy/landwich-api-types/api";
import useLogout from "@/shared/auth/api/mutations/useLogout";
import HoverContextContainer, {
  useHoverContext,
} from "../../context/HoverContextContainer";
import { ROUTER_PATH } from "../../constants/router";
import useModal from "../../hooks/useModal";
import UserInfoFallback from "./Fallback";
import ProfileSettingModal from "./ProfileSettingModal";

interface Props {
  userInfo?: GetUserDetailResponseDataDto;
}

const UserInfo = ({ userInfo }: Props): JSX.Element => {
  if (isUndefined(userInfo)) {
    return <UserInfoFallback />;
  }

  return (
    <HoverContextContainer>
      <UserInfoContent
        email={userInfo.email ?? ""}
        firstName={userInfo.firstName ?? ""}
        lastName={userInfo.lastName ?? ""}
        picture={userInfo.picture ?? ""}
      />
    </HoverContextContainer>
  );
};

interface UserInfoContentProps {
  picture: string;
  firstName: string;
  lastName: string;
  email: string;
}

const UserInfoContent = ({
  picture,
  firstName,
  lastName,
  email,
}: UserInfoContentProps): JSX.Element => {
  const router = useRouter();
  const { isHover } = useHoverContext();
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const {
    isOpen: isProfileSettingModalOpen,
    openModal: openProfileSettingModal,
    closeModal: closeProfileSettingModal,
  } = useModal();

  const { isLoading, startLoading } = useLoadingState();

  const { mutate } = useLogout();

  const handleLogout = () => {
    setIsUserDropdownOpen(true);
    startLoading();
    mutate(
      {},
      {
        onSuccess: () => {
          router.replace(ROUTER_PATH.login);
        },
      }
    );
  };

  return (
    <>
      <DropdownMenu.Root
        defaultOpen={false}
        onOpenChange={(open: boolean) => setIsUserDropdownOpen(open)}
        open={isUserDropdownOpen}
      >
        <DropdownMenu.Trigger asChild>
          <div
            className={cn(
              "flex p-4 justify-between h-fit items-center w-[288px] rounded-lg hover:cursor-pointer",
              {
                "bg-charcoal_100": isUserDropdownOpen || isHover,
              }
            )}
          >
            <Image
              alt="user profile image"
              className="h-[38px] w-[38px] rounded-full border-[1px] border-white bg-charcoal_400"
              height={38}
              src={picture ?? ""}
              width={38}
            />
            <div className="flex flex-col gap-1 w-[153px]">
              <div
                className={cn("text-md-semibold truncate", {
                  "text-black": isHover || isUserDropdownOpen,
                  "text-charcoal_500": !isHover && !isUserDropdownOpen,
                })}
              >
                {firstName} {lastName}
              </div>
              <div
                className={cn("text-sm-medium", {
                  "text-charcoal_500": isHover || isUserDropdownOpen,
                  "text-charcoal_400": !isHover && !isUserDropdownOpen,
                })}
              >
                {email}
              </div>
            </div>
            <button className="w-fit h-full transition-all" type="button">
              <Icon
                color={isHover || isUserDropdownOpen ? "black" : "charcoal_500"}
                icon="ph:caret-down"
                size={17}
              />
            </button>
          </div>
        </DropdownMenu.Trigger>

        <AnimateDropdownWrapper
          align="center"
          className="rounded-lg border-[1px]  border-charcoal_200 bg-white shadow-md w-[288px] h-fit overflow-hidden hover:cursor-pointer"
          zIndexClass="z-10"
        >
          <DropdownMenu.DropdownMenuItem
            className="flex gap-2 p-4 bg-white hover:bg-charcoal_100 border-b-[1px] border-charcoal_200 outline-none"
            onClick={openProfileSettingModal}
          >
            <Icon color="charcoal_500" icon="ph:user-circle" size={17} />
            <span className="text-md-medium text-charcoal_500">My Profile</span>
          </DropdownMenu.DropdownMenuItem>
          <DropdownMenu.DropdownMenuItem
            className="flex gap-2 p-4 bg-white hover:bg-charcoal_100 outline-none"
            onSelect={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleLogout();
            }}
          >
            {isLoading ? (
              <LoadingSpinner color="cerise_500" size="sm" />
            ) : (
              <Icon color="cerise_500" icon="ph:sign-out" size={17} />
            )}

            <span className="text-md-medium text-cerise_500">Sign out</span>
          </DropdownMenu.DropdownMenuItem>
        </AnimateDropdownWrapper>
      </DropdownMenu.Root>
      <ProfileSettingModal
        closeModal={closeProfileSettingModal}
        isOpen={isProfileSettingModalOpen}
      />
    </>
  );
};

export default UserInfo;
