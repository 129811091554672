import type { GetUserDetailResponseDataDto } from "@our-pairy/landwich-api-types/api";
import Image from "next/image";
import { getDateFormat } from "@/shared/@common/utils/getDateFormat";

interface Props {
  userInfo: GetUserDetailResponseDataDto;
}

const ProfileInfo = ({ userInfo }: Props) => {
  return (
    <div className="flex gap-4 p-4">
      <Image
        alt="user profile image"
        className="h-[40px] w-[40px] rounded-full border-[1px] border-white bg-charcoal_400"
        height={38}
        src={userInfo.picture}
        width={38}
      />
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <span className="text-xl-semibold">
            {userInfo?.firstName} {userInfo?.lastName}
          </span>
          <span className="text-sm-medium text-charcoal_500">
            {userInfo?.email}
          </span>
        </div>
        <div className="flex flex-col gap-1">
          <span className="text-sm-medium text-charcoal_500">joined on</span>
          <span className="text-md-medium">
            {getDateFormat(userInfo.createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ProfileInfo;
