import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
  type HTMLAttributes,
} from "react";

interface HoverContextType {
  isHover: boolean;
}

const HoverContext = createContext<null | HoverContextType>(null);

export const useHoverContext = (): { isHover: boolean } => {
  const context = useContext(HoverContext);

  if (context === null) {
    throw Error("not found hover context error");
  }

  return {
    isHover: context.isHover,
  };
};

const HoverContextContainer = ({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>): JSX.Element => {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      className={`hover:cursor-pointer ${className}`}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      {...props}
    >
      <HoverContext.Provider value={{ isHover }}>
        {children}
      </HoverContext.Provider>
    </div>
  );
};

export default HoverContextContainer;
