import type { HTMLAttributes, PropsWithChildren } from "react";
import cn from "classnames";
import React from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpacity: boolean;
}

const OpacityLayer = ({
  className,
  children,
  isOpacity,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={cn(className, {
        "opacity-50 pointer-events-none": isOpacity,
      })}
      {...props}
    >
      {children}
    </div>
  );
};

export default OpacityLayer;
