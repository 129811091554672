import { useSetAtom } from "jotai";
import { type ToastItemType } from "@landwich/shared/types";
import { toastAtom } from "../store/index.atom";

interface ReturnType {
  showToast: ({
    message,
    type,
    handleOnError,
  }: Omit<ToastItemType, "id">) => void;
}

export const useToast = (): ReturnType => {
  const setToasts = useSetAtom(toastAtom);

  const showToast = ({
    message,
    type,
    handleOnError,
  }: Omit<ToastItemType, "id">): void => {
    const newToast: ToastItemType = {
      message,
      id: Date.now(),
      type,
      handleOnError,
    };
    setToasts((pre) => [...pre, newToast]);

    setTimeout(() => {
      setToasts((pre) => pre.filter((item) => item.id !== newToast.id));
    }, 5000);
  };

  return {
    showToast,
  };
};
