import Image from "next/image";
import type { GetUserDetailResponseDataDto } from "@our-pairy/landwich-api-types/api";
import UserInfo from "../UserInfo";

interface Props {
  userInfo?: GetUserDetailResponseDataDto;
}

const Header = ({ userInfo }: Props): JSX.Element => {
  //const pathname = usePathname();

  // const isComparisonPage = pathname === ROUTER_PATH.comparison;
  // const isCompetitorPage = pathname === ROUTER_PATH.competitors;

  return (
    <div className="w-full h-fit flex justify-between items-center">
      <div className="flex gap-6 justify-center flex-row">
        <Image
          alt="landwich logo image"
          height={22}
          quality={100}
          src="/logo/landwich-logo.png"
          style={{ objectFit: "contain" }}
          width={100}
        />
        {/* <div className="flex gap-2 justify-center flex-row">
          <Link
            className={cn(
              "px-6 py-4 rounded-lg hover:bg-charcoal_500 hover:text-white text-md-semibold h-fit",
              {
                "bg-charcoal_500 text-white": isComparisonPage,
                "bg-transparent text-charcoal_300": !isComparisonPage,
              }
            )}
            href={ROUTER_PATH.comparison}
            type="button"
          >
            Comparison Pages
          </Link>
          <Link
            className={cn(
              "px-6 py-4 rounded-lg hover:bg-charcoal_500 hover:text-white text-md-semibold h-fit",
              {
                "bg-charcoal_500 text-white": isCompetitorPage,
                "bg-transparent text-charcoal_300": !isCompetitorPage,
              }
            )}
            href={ROUTER_PATH.competitors}
            type="button"
          >
            Competitors
          </Link>
        </div> */}
      </div>
      <UserInfo userInfo={userInfo} />
    </div>
  );
};

export default Header;
