import { Button, Icon } from "@landwich/ds";
import { isUndefined } from "@landwich/shared/utils";
import React from "react";

interface Props {
  retryHandler: () => void;
  exitHandler?: () => void;
  title: string;
  description?: string;
}
const GenericErrorBox = ({
  exitHandler,
  retryHandler,
  title,
  description = `There was a problem loading the data.\nPlease check your network connection\nand try again.`,
}: Props) => {
  return (
    <div className="flex flex-col gap-8 items-center p-16 bg-white w-[480px] rounded-lg">
      <div className="flex flex-col gap-4 items-center w-full">
        <Icon color="cerise_500" icon="ph:lightning-slash" size={64} />
        <h2 className="text-xl-semibold text-cerise_500">{title}</h2>
        <p className="text-md-medium text-cerise_500 whitespace-pre text-center">
          {description}
        </p>
      </div>
      <div className="flex items-center gap-2">
        {!isUndefined(exitHandler) && (
          <Button onClick={exitHandler} paddingX={8} paddingY={4}>
            Exit
          </Button>
        )}
        <Button className="w-[200px]" color="black" onClick={retryHandler}>
          Retry
        </Button>
      </div>
    </div>
  );
};

export default GenericErrorBox;
