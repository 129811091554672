import type { ReactElement, ReactNode } from "react";
import { Children, cloneElement, useState } from "react";

const useModal = (children?: ReactNode) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = (): void => {
    setIsOpen(true);
  };

  const closeModal = (): void => {
    setIsOpen(false);
  };

  const triggerChildren = Children.map(
    children as ReactElement,
    (child: ReactElement) => {
      const existingOnClick = child.props.onClick;

      const combinedOnClick = (event: React.MouseEvent) => {
        if (existingOnClick) {
          existingOnClick(event);
        }
        openModal();
      };

      return cloneElement(child, { onClick: combinedOnClick });
    }
  );

  return {
    isOpen,
    triggerChildren,
    openModal,
    closeModal,
  };
};

export default useModal;
