import { Icon, Input } from "@landwich/ds";
import React from "react";
import type {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import type { ProductInfo } from "@/shared/@common/constants/productInfoZod";

interface Props {
  watch: UseFormWatch<ProductInfo>;
  register: UseFormRegister<ProductInfo>;
  setValue: UseFormSetValue<ProductInfo>;
  errors: FieldErrors<ProductInfo>;
}

const ProductInfoForm = ({ watch, register, setValue, errors }: Props) => {
  return (
    <>
      <Input
        errorMessage={errors.productName?.message}
        isError={Boolean(errors.productName)}
        label="Product Name"
        placeholder="e.g.) Landwich"
        type="text"
        {...register("productName")}
      />
      <Input
        errorMessage={errors.mainPageUrl?.message}
        isError={Boolean(errors.mainPageUrl)}
        label="Product Page URL"
        placeholder="e.g.) https://landwi.ch"
        type="text"
        {...register("mainPageUrl")}
      />
      <div className="flex flex-col gap-4">
        <div className="flex items-start gap-4">
          <label htmlFor="pricing">
            {watch("isHasPricingPage") ? (
              <Icon
                color="charcoal_500"
                icon="ph:check-square-fill"
                size={19}
              />
            ) : (
              <Icon color="black" icon="ph:square" size={19} />
            )}
          </label>
          <Input
            className="hidden"
            id="pricing"
            {...register("isHasPricingPage", {
              onChange: () => {
                setValue("pricingPageUrl", watch("mainPageUrl"));
              },
            })}
            type="checkbox"
          />
          <section className="flex flex-col gap-4 w-full">
            <article className="flex flex-col gap-2">
              <span className="text-lg-semibold text-black">
                Add Pricing info
              </span>
              <span className="text-sm-medium text-charcoal_500">
                *Check this box if you have a separate pricing page
              </span>
            </article>
            {watch("isHasPricingPage") && (
              <Input
                block
                errorMessage={errors.pricingPageUrl?.message}
                isError={Boolean(errors.pricingPageUrl)}
                placeholder="e.g.) https://landwi.ch/pricing"
                type="text"
                {...register("pricingPageUrl")}
              />
            )}
          </section>
        </div>
      </div>
    </>
  );
};

export default ProductInfoForm;
