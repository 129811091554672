import { Suspense, type SuspenseProps } from "react";
import { useMounted } from "@landwich/shared/hooks";

export const SSRSafeSuspense = ({
  children,
  fallback,
}: SuspenseProps): JSX.Element => {
  const isMounted = useMounted();

  return isMounted ? (
    <Suspense fallback={fallback}>{children}</Suspense>
  ) : (
    <>{fallback}</>
  );
};
