import { useMutation } from "@tanstack/react-query";
import type { MutationOptions, MutationResult } from "@landwich/shared/types";
import { post } from "@landwich/shared/utils/api";

const logout = () => {
  return post("/v1/auth/logout");
};

const useLogout = (options?: MutationOptions): MutationResult => {
  return useMutation({ mutationFn: logout, ...options });
};

export default useLogout;
