import type { HTMLAttributes } from "react";
import { Icon, type IconNames } from "@landwich/ds";
import type { ColorKeyType } from "@landwich/shared/types";

interface Props extends HTMLAttributes<HTMLButtonElement> {
  color: ColorKeyType;
  hoverColor: ColorKeyType;
  icon: IconNames;
  onClick: () => void;
}

const CircleButton = ({ color, hoverColor, onClick, icon, ...rest }: Props) => {
  return (
    <button
      className={`rounded-full p-[6px] bg-white flex justify-center items-center hover:bg-${hoverColor} transition-all`}
      onClick={onClick}
      type="button"
      {...rest}
    >
      <Icon color={color} icon={icon} size={17} />
    </button>
  );
};

export default CircleButton;
