import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import type { UseFormReturn } from "react-hook-form";
import { Form } from "@landwich/ds";
import {
  productInfoSchema,
  type ProductInfo,
} from "@/shared/@common/constants/productInfoZod";

interface Props {
  onSubmit: (data: ProductInfo) => void;
  children: (methods: UseFormReturn<ProductInfo>) => React.ReactNode;
}

const ProductInfoLayout = ({ onSubmit, children }: Props) => {
  return (
    <Form<ProductInfo>
      className="h-full"
      defaultValues={{
        productName: "",
        mainPageUrl: "",
        isHasPricingPage: false,
        pricingPageUrl: "",
      }}
      onSubmit={onSubmit}
      resolver={zodResolver(productInfoSchema)}
    >
      {children}
    </Form>
  );
};

export default ProductInfoLayout;
