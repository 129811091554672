import { type PropsWithChildren } from "react";
import FigmaHeader from "./FigmaHeader";

const FigmaHeaderLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className="relative h-full w-full max-w-[480px]">
      <div className="sticky top-0">
        <FigmaHeader />
      </div>

      {children}
    </div>
  );
};

export default FigmaHeaderLayout;
