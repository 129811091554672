import { useRouter } from "next/navigation";
import type { FallbackProps } from "react-error-boundary";

export const useErrorBoundaryUnauthorized = ({
  error,
  resetErrorBoundary,
}: FallbackProps): (() => void) => {
  const router = useRouter();
  const isUnauthorized = error.response?.status === 401;

  const handleButtonClick = (): void => {
    if (isUnauthorized) {
      router.push("/login");
    }

    resetErrorBoundary();
  };

  return handleButtonClick;
};

export default useErrorBoundaryUnauthorized;
