import { Button, LoadingSpinner } from "@landwich/ds";
import type { ButtonHTMLAttributes, PropsWithChildren } from "react";
import React from "react";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean;
}

const PostLoadingButton = ({
  children,
  disabled,
  isLoading,
  ...props
}: PropsWithChildren<Props>) => {
  return (
    <Button
      className="!w-[200px] flex items-center justify-center"
      disabled={disabled}
      {...props}
      color="black"
      type="submit"
    >
      {isLoading ? <LoadingSpinner color="charcoal_400" size="sm" /> : children}
    </Button>
  );
};

export default PostLoadingButton;
