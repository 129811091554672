import React from "react";
import cn from "classnames";

interface Props {
  headers: string[];
  rows: string[][];
}

const Table = ({ headers, rows }: Props) => {
  return (
    <table className="w-full rounded-lg border border-separate border-charcoal_200 table-fixed overflow-hidden">
      <thead>
        <tr className="bg-charcoal_100">
          {headers.map((header, index) => (
            <th
              className={cn(
                `w-1/${headers.length} p-4 text-start text-lg-medium text-charcoal_600`,
                {
                  "rounded-tl-lg": index === 0,
                  "rounded-tr-lg": index === headers.length - 1,
                }
              )}
              key={index}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr className="bg-white" key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td
                className={cn("p-4 text-lg-medium text-charcoal_600", {
                  "rounded-bl-lg": cellIndex === 0,
                  "rounded-br-lg": cellIndex === row.length - 1,
                })}
                key={cellIndex}
              >
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
