import type { IconNames } from "@landwich/ds";
import { Button, Icon } from "@landwich/ds";
import React from "react";

interface Props {
  icon?: IconNames;
  iconSize?: number;
  title?: string;
  description: string;
  handler: () => void;
}

const CommonErrorFallbackUi = ({
  icon = "ph:warning-octagon",
  iconSize = 64,
  title,
  description,
  handler,
}: Props) => {
  return (
    <>
      <Icon color="cerise_500" icon={icon} size={iconSize} />
      {title && (
        <span className="text-xl-semibold text-cerise_500 text-center">
          {title}
        </span>
      )}
      <span className="text-lg-semibold text-cerise_500 text-center whitespace-pre-line">
        {description}
      </span>
      <Button
        color="black"
        icon="ph:arrows-clockwise"
        iconGap={1}
        onClick={handler}
        paddingX={4}
        paddingY={2}
        rounded="rounded"
      >
        Retry
      </Button>
    </>
  );
};

export default CommonErrorFallbackUi;
