import React from "react";
import Skeleton from "../Skeleton";

const UserInfoFallback = () => {
  return (
    <div className="flex items-center gap-2 w-[288px] bg-charcoal_100 px-4 py-3 rounded-lg">
      <Skeleton height={40} width={40} />
      <div className="flex flex-col gap-1 w-full">
        <Skeleton block height={17} />
        <Skeleton height={15} width={150} />
      </div>
    </div>
  );
};

export default UserInfoFallback;
