import { useMutation } from "@tanstack/react-query";
import type { MutationOptions, MutationResult } from "@landwich/shared/types";
import { remove } from "@landwich/shared/utils/api";

const deleteUser = () => {
  return remove("/v1/user");
};

const useDeleteUser = (options?: MutationOptions): MutationResult => {
  return useMutation({ mutationFn: deleteUser, ...options });
};

export default useDeleteUser;
