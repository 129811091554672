import { type MouseEvent, useRef } from "react";
import { useLoadingState } from "@landwich/shared/hooks";
import useModal from "@/shared/@common/hooks/useModal";
import useDeleteUser from "@/shared/auth/api/mutations/useDeleteUser";
import { useToast } from "@/shared/@common/hooks/useToast";
import useGetUserInfo from "@/shared/auth/api/queries/useGetUserInfo";
import SettingModal from "../../SettingModal";
import BasicAlertModal from "../../BasicAlertModal";
import DangerZone from "../../DangerZone";
import ProfileInfo from "./ProfileInfo";

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

const ProfileSettingModal = ({ isOpen, closeModal }: Props) => {
  const toast = useToast();
  const alertModalRef = useRef<HTMLDivElement>(null);

  const { data: userData } = useGetUserInfo();

  const {
    isLoading: isDeleteUserLoading,
    startLoading: startDeleteUserLoading,
    stopLoading: stopDeleteUserLoading,
  } = useLoadingState();

  const {
    isOpen: isDeleteWarningModalOpen,
    openModal: openDeleteWarningModal,
    closeModal: closeDeleteWarningModal,
  } = useModal();

  const { mutate: mutateDeleteUser } = useDeleteUser();

  const handleDeleteUserRequest = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    startDeleteUserLoading();
    mutateDeleteUser(
      {},
      {
        onSuccess: () => {
          stopDeleteUserLoading();
          closeDeleteWarningModal();
          window.location.replace("https://landwi.ch/deleted-account");
        },
        onError: () => {
          toast.showToast({
            type: "error",
            message:
              "A network error has occurred. Please check your network settings.",
          });
        },
      }
    );
  };

  //  추후 에러처리
  if (!userData?.data) {
    return null;
  }

  return (
    <SettingModal
      closeModal={closeModal}
      icon="ph:user-circle"
      isOpen={isOpen}
      restRefs={[alertModalRef]}
      title="Profile"
    >
      <div className="flex flex-col gap-8">
        <ProfileInfo userInfo={userData.data} />
        <DangerZone
          deleteHandler={openDeleteWarningModal}
          description={`Once deleted, your account cannot be recovered\nAll your data and content will be permanently deleted.`}
        />

        <BasicAlertModal
          changeText="Delete"
          closeModal={closeModal}
          description={`Once deleted, your account cannot be recovered.\nAll your data and content will be permanently deleted.`}
          handleChangeButtonClick={handleDeleteUserRequest}
          isButtonLoading={isDeleteUserLoading}
          isOpen={isDeleteWarningModalOpen}
          level={1}
          modalRef={alertModalRef}
          title={
            <div>
              <b>Delete your account?</b>
            </div>
          }
        />
      </div>
    </SettingModal>
  );
};

export default ProfileSettingModal;
