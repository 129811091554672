import type { AxiosRequestConfig } from "axios";
import axios from "axios";
import type { ApiResponse } from "../types";
import { isWindowDefined } from "./isWindowDefined";

const DEV_HOST = "devoculus.landwi.ch";
const DEV_VIEWER_HOST = "dev.lclu.io";

export const BASE_URL = {
  local: "http://localhost:3000/local",
  dev: "https://api_dev.landwi.ch",
  main: "https://api.landwi.ch",
};

export const getBaseUrl = (): string => {
  if (isWindowDefined()) {
    if (window?.location.hostname.includes("local")) {
      return BASE_URL.dev;
    } else if (
      window?.location.host === DEV_HOST ||
      window.location.hostname.endsWith(DEV_VIEWER_HOST)
    ) {
      return BASE_URL.dev;
    }
    return BASE_URL.main;
  }

  return BASE_URL.dev;
};

export const api = axios.create({
  withCredentials: true,
  baseURL: getBaseUrl(),
});

export const get = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await api.get<ApiResponse<T>>(url, config);
  return response.data;
};

export const post = async <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await api.post<ApiResponse<T>>(url, data, config);
  return response.data;
};

export const put = async <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await api.put<ApiResponse<T>>(url, data, config);
  return response.data;
};

export const patch = async <T>(
  url: string,
  data?: unknown,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await api.patch<ApiResponse<T>>(url, data, config);
  return response.data;
};

export const remove = async <T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<ApiResponse<T>> => {
  const response = await api.delete<ApiResponse<T>>(url, config);
  return response.data;
};
