import { LoadingSpinner } from "@landwich/ds";
import { type PropsWithChildren, type HTMLAttributes } from "react";

const Loading = ({
  children,
  className,
  ...rest
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {
  return (
    <div
      className={`${className} bg-charcoal_100 rounded-lg p-6 flex flex-col gap-4 items-center justify-center`}
      {...rest}
    >
      <LoadingSpinner color="charcoal_400" size="lg" />
      <div className="text-lg-semibold text-charcoal_400">{children}</div>
    </div>
  );
};

export default Loading;
