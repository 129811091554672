import { Suspense, type PropsWithChildren, type ReactNode } from "react";
import {
  ErrorBoundary,
  type ErrorBoundaryPropsWithRender,
  type ErrorBoundaryProps,
} from "react-error-boundary";
import { useQueryErrorResetBoundary } from "@tanstack/react-query";
import { SSRSafeSuspense } from "./SSRSafeSuspense";

type Props = Omit<
  ErrorBoundaryProps,
  "fallbackRender" | "FallbackComponent"
> & {
  errorFallback: ErrorBoundaryPropsWithRender["fallbackRender"];
  fallback?: ReactNode;
  isSSRRender?: boolean;
};

export const AsyncBoundary = ({
  errorFallback,
  fallback,
  children,
  isSSRRender = false,
  ...errorBoundaryProps
}: PropsWithChildren<Props>): JSX.Element => {
  const { reset } = useQueryErrorResetBoundary();

  return (
    <ErrorBoundary
      fallbackRender={errorFallback}
      {...errorBoundaryProps}
      onReset={reset}
    >
      {isSSRRender ? (
        <SSRSafeSuspense fallback={fallback}>{children}</SSRSafeSuspense>
      ) : (
        <Suspense fallback={fallback}>{children}</Suspense>
      )}
    </ErrorBoundary>
  );
};
