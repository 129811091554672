import React from "react";
import Skeleton from "../Skeleton";

const RecommendedTargetAudienceFallback = () => (
  <div className="flex flex-col gap-4 p-6 bg-charcoal_100 rounded-lg">
    {[...Array(3)].map((_, index) => (
      <Skeleton block height={67} key={index} rounded="rounded-lg" />
    ))}
  </div>
);

export default RecommendedTargetAudienceFallback;
