import { useQuery } from "@tanstack/react-query";
import type { QueryResult, WithQueryOptions } from "@landwich/shared/types";
import { get } from "@landwich/shared/utils/api";
import type { GetUserDetailResponseDataDto } from "@our-pairy/landwich-api-types/api";
import { userKey } from "../key";

type GetUserInfoRequest = WithQueryOptions<GetUserDetailResponseDataDto>;

const getUserInfo = () => {
  return get<GetUserDetailResponseDataDto>("/v1/user");
};

const useGetUserInfo = ({
  options,
}: GetUserInfoRequest = {}): QueryResult<GetUserDetailResponseDataDto> => {
  return useQuery({
    queryKey: userKey.all(),
    queryFn: () => getUserInfo(),
    ...options,
  });
};

export default useGetUserInfo;
