import Image from "next/image";
import FigmaMenuDropdown from "./FigmaMenuDropdown";

const FigmaHeader = () => {
  return (
    <header className="px-8 py-4 w-full h-fit flex justify-between border-b border-b-charcoal_200 bg-white z-10">
      <Image
        alt="landwich logo"
        height={24}
        src="/logo/landwich-logo.png"
        style={{
          objectFit: "contain",
        }}
        width={120}
      />
      <FigmaMenuDropdown />
    </header>
  );
};

export default FigmaHeader;
