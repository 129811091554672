import { useErrorBoundaryUnauthorized } from "@landwich/react-async-boundary";
import type { FallbackProps } from "react-error-boundary";
import CommonErrorFallbackUi from "../CommonErrorFallbackUi";

const RecommendedTargetAudienceErrorFallback = ({
  error,
  resetErrorBoundary,
}: FallbackProps): JSX.Element => {
  const handleButtonClick = useErrorBoundaryUnauthorized({
    error,
    resetErrorBoundary,
  });

  return (
    <div className="flex flex-col gap-4 p-6 items-center bg-cerise_100 rounded-lg">
      <CommonErrorFallbackUi
        description={`An error occurred\nwhile creating targets.`}
        handler={handleButtonClick}
      />
    </div>
  );
};

export default RecommendedTargetAudienceErrorFallback;
