import { Button, Icon } from "@landwich/ds";
import React from "react";

interface Props {
  description: string;
  deleteHandler: () => void;
}

const DangerZone = ({ description, deleteHandler }: Props) => {
  return (
    <div className="flex flex-col gap-4 items-start p-4 bg-cerise_50 rounded-lg">
      <div className="flex items-center gap-4">
        <Icon color="cerise_500" icon="ph:warning" size={19} />
        <h2 className="text-xl-semibold text-cerise_500">Danger Zone</h2>
      </div>
      <div className="flex flex-col gap-4 pl-[35px]">
        <span className="text-md-medium text-cerise_500 whitespace-pre-line">
          {description}
        </span>
        <Button
          className="w-fit"
          color="red"
          icon="ph:trash"
          onClick={deleteHandler}
          paddingY={2}
          rounded="rounded"
          textVariant="text-sm-medium"
        >
          Delete Landwich Account
        </Button>
      </div>
    </div>
  );
};

export default DangerZone;
