import { z } from "zod";
import { urlRegex } from "@/shared/@common/constants/regex";

export type ProductInfo = z.infer<typeof productInfoSchema>;

const urlErrorMessage =
  "Please enter a valid URL starts with http:// or https://.";

const baseSchema = z.object({
  productName: z
    .string()
    .regex(/^[A-Za-z가-힣0-9\s]+$/, "Please enter product name."),
  mainPageUrl: z.string().regex(urlRegex, urlErrorMessage),
});

export const productInfoSchema = z.discriminatedUnion("isHasPricingPage", [
  baseSchema.extend({
    isHasPricingPage: z.literal(false),
    pricingPageUrl: z.string().optional(),
  }),
  baseSchema.extend({
    isHasPricingPage: z.literal(true),
    pricingPageUrl: z.string().regex(urlRegex, urlErrorMessage),
  }),
]);
