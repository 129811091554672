import React from "react";
import cn from "classnames";

interface Props {
  className?: string;
  width?: number;
  height?: number;
  block?: boolean;
  bgColor?: "bg-charcoal_100" | "bg-charcoal_200";
  rounded?:
    | "rounded"
    | "rounded-lg"
    | "rounded-xl"
    | "rounded-2xl"
    | "rounded-full";
}

const Skeleton = ({
  className,
  width,
  height,
  block,
  bgColor = "bg-charcoal_200",
  rounded = "rounded-full",
}: Props) => {
  return (
    <div
      className={cn(`${className} animate-pulse ${bgColor} ${rounded}`, {
        "w-full": block,
      })}
      style={{
        minWidth: `${width}px`,
        minHeight: `${height}px`,
        width: `${width}px`,
        height: `${height}px`,
      }}
    />
  );
};

export default Skeleton;
